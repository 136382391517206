/** BODY **/
body {
  background-color:rgb(0,0,0);
  color:rgb(255,255,255);
}

/** HEADER **/
#box-2 {
  background-size: cover;
  background: no-repeat 50% 0%;
  height: 580px;
}
#box-2 h3{
  box-2




/** Search Box **/
.search-box{
  margin-bottom: 20px;
  background-color: white;
  color: #0c0c0c;
}
  a.btn-common{
    font-weight: bold !important;
  }

  /** sections **/
  .section {
    padding: 80px 0;
  }

  /** PORTFOLIO**/
  #portfolio .portfolio-item{
    /*min-width: 175px;*/
  }


  /** PRICING TABLE **/

  .pricing-table{
    border-radius: 10px;
    margin-left: 45px;
    margin-right: 45px;
    padding-bottom: 0px;
  }

  .price-box{
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 35px;
  }

  .pricing-info{
    font-size: 25px;
  }

  .pricing-table .pricing-details span{
    font-size: 25px;
    margin-bottom: 0px;
    line-height: 30px;
  }

  .pricing-table span.pricing-time{
    text-transform: uppercase;
    display: block;
    font-size: 30px;
    font-weight: bold;
  }

  .pricing-table span.pricing-text{
    font-size: 18px;
    display: block;
  }

  .pricing-details img{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /** TEAM **/
  .single-team{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .single-team,
  .single-team .team-details {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}