@charset "UTF-8";
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #61D2B4; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; }

/** BODY **/
body {
  background-color: black;
  color: white; }

/** HEADER **/
#box-2 {
  background-size: cover;
  background: no-repeat 50% 0%;
  height: 580px; }

#box-2 h3 {
  /** sections **/
  /** PORTFOLIO**/
  /** PRICING TABLE **/
  /** TEAM **/ }
  #box-2 h3 box-2
.search-box {
    margin-bottom: 20px;
    background-color: white;
    color: #0c0c0c; }
  #box-2 h3 a.btn-common {
    font-weight: bold !important; }
  #box-2 h3 .section {
    padding: 80px 0; }
  #box-2 h3 #portfolio .portfolio-item {
    /*min-width: 175px;*/ }
  #box-2 h3 .pricing-table {
    border-radius: 10px;
    margin-left: 45px;
    margin-right: 45px;
    padding-bottom: 0px; }
  #box-2 h3 .price-box {
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 35px; }
  #box-2 h3 .pricing-info {
    font-size: 25px; }
  #box-2 h3 .pricing-table .pricing-details span {
    font-size: 25px;
    margin-bottom: 0px;
    line-height: 30px; }
  #box-2 h3 .pricing-table span.pricing-time {
    text-transform: uppercase;
    display: block;
    font-size: 30px;
    font-weight: bold; }
  #box-2 h3 .pricing-table span.pricing-text {
    font-size: 18px;
    display: block; }
  #box-2 h3 .pricing-details img {
    margin-top: 30px;
    margin-bottom: 30px; }
  #box-2 h3 .single-team {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  #box-2 h3 .single-team,
  #box-2 h3 .single-team .team-details {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

h4#footer-header {
  font-size: 20px;
  margin-bottom: 5px; }

#footer-container i {
  margin-left: 5px; }

#footer-container li:last-child {
  border-right: none; }

#footer-container li {
  font-size: medium;
  font-weight: normal;
  padding: 10px;
  border-right: 1px solid gray;
  margin-top: 7px;
  float: left;
  white-space: nowrap; }

#footer-container {
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  text-align: center;
  padding: 5px; }

h4#footer-header {
  color: #dedede;
  font-weight: normal; }

footer {
  box-shadow: 0 1px 1px 0 rgba 0, 0, 0, 0.12;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 25px 75px;
  bottom: 0;
  position: fixed;
  font-size: 11px;
  color: #dedede; }

#footer-header span {
  color: #dedede;
  font-weight: 100;
  vertical-align: super;
  font-size: smaller; }

footer {
  position: relative;
  padding: 20px; }

.footer-container ul {
  display: inline-block;
  margin: 15px; }

.footer .list-inline {
  display: inline-block;
  margin: 10px; }

.epitaph {
  font-size: 16px;
  font-weight: normal;
  margin: 15px; }
