h4#footer-header {
    font-size: 20px;
    margin-bottom: 5px;
}

#footer-container i{
    margin-left:5px;
}

#footer-container li:last-child{
    border-right:none;
}

#footer-container li{
    font-size:medium;
    font-weight:normal;
    padding:10px;
    border-right:1px solid gray;
    margin-top:7px;
    float: left;
    white-space: nowrap;
}

#footer-container{
    border-bottom:1px solid white;
    border-top:1px solid white;
    text-align:center;
    padding:5px;
}

h4#footer-header{
    color:#dedede;
    font-weight:normal;
}

footer{
    box-shadow:0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing:border-box;
    width:100%;
    text-align:left;
    font:bold 16px sans-serif;
    padding:25px 75px;
    bottom:0;
    position:fixed;
    font-size:11px;
    color:#dedede;
}

#footer-header span{
    color:#dedede;
    font-weight:100;
    vertical-align:super;
    font-size:smaller;
}

footer{
    position:relative;
    padding:20px;
}

.footer-container ul{
    display: inline-block;
    margin: 15px;
}

.footer .list-inline {
    display: inline-block; margin: 10px;
}

.epitaph{
    font-size: 16px;
    font-weight: normal;
    margin: 15px;
}